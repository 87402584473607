"use strict";

var _interopRequireDefault = require("D:/dy/outside/iot_console/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _RightPanel = _interopRequireDefault(require("@/components/RightPanel"));
var _components = require("./components");
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Layout",
  components: {
    AppMain: _components.AppMain,
    Navbar: _components.Navbar,
    RightPanel: _RightPanel.default,
    Settings: _components.Settings,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView,
    siderbarHeader: _components.siderbarHeader
  },
  mixins: [_ResizeHandler.default],
  data: function data() {
    return {
      curYear: 0
    };
  },
  computed: {
    sideBarTheme: function sideBarTheme() {
      return this.$store.state.setting.sideBarTheme;
    },
    sidebar: function sidebar() {
      return this.$store.state.setting.sidebar;
    },
    device: function device() {
      return this.$store.state.setting.device;
    },
    showSettings: function showSettings() {
      return this.$store.state.setting.settingBar.opened;
    },
    needTagsView: function needTagsView() {
      return this.$store.state.setting.multipage;
    },
    fixedHeader: function fixedHeader() {
      return this.$store.state.setting.fixHeader;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  },
  created: function created() {
    this.curYear = new Date().getFullYear();
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.commit("setting/closeSidebar", {
        withoutAnimation: false
      });
    }
  }
};