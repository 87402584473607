"use strict";

var _interopRequireDefault = require("D:/dy/outside/iot_console/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _jspdf = _interopRequireDefault(require("jspdf"));
// 导出页面为PDF格式
var _default = exports.default = {
  install: function install(Vue, options) {
    Vue.prototype.getPdf = function (dom, params) {
      // var title = this.htmlTitle;
      var title = params.title;
      (0, _html2canvas.default)(document.querySelector("#pdfDom"), {
        allowTaint: true
      }).then(function (canvas) {
        var contentWidth = canvas.width;
        var contentHeight = canvas.height;
        var pageHeight = contentWidth / 592.28 * 841.89;
        var leftHeight = contentHeight;
        var position = 0;
        var imgWidth = 595.28;
        var imgHeight = 592.28 / contentWidth * contentHeight;
        var pageData = canvas.toDataURL("image/jpeg", 1.0);
        var PDF = new _jspdf.default("", "pt", "a4");
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        PDF.save(title + ".pdf");
      });
    };
  }
};