"use strict";

var _interopRequireDefault = require("D:/dy/outside/iot_console/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getExpireTime = getExpireTime;
exports.getRefreshToken = getRefreshToken;
exports.getRouteToken = getRouteToken;
exports.getRouteTokenExpireTime = getRouteTokenExpireTime;
exports.getToken = getToken;
exports.removeRouteToken = removeRouteToken;
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
function getToken() {
  return _localstorage.default.get('ACCESS_TOKEN', '');
}
function getRefreshToken() {
  return _localstorage.default.get('REFRESH_TOKEN', '');
}
function getExpireTime() {
  return _localstorage.default.get('EXPIRE_TIME', 0);
}
function getRouteTokenExpireTime() {
  return _localstorage.default.get('ROUTE_TOKEN_EXPIRE_TIME', 0);
}
function getRouteToken() {
  return _localstorage.default.get('ROUTE_TOKEN', '');
}
function removeRouteToken() {
  return _localstorage.default.remove('ROUTE_TOKEN');
}