"use strict";

var _interopRequireDefault = require("D:/dy/outside/iot_console/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isArray = isArray;
exports.isExternal = isExternal;
exports.isIntegerGreaterThanZero = isIntegerGreaterThanZero;
exports.isString = isString;
exports.validAlphabets = validAlphabets;
exports.validEmail = validEmail;
exports.validLowerCase = validLowerCase;
exports.validMobile = validMobile;
exports.validMobileRule = validMobileRule;
exports.validPositiveNumber = validPositiveNumber;
exports.validURL = validURL;
exports.validUpperCase = validUpperCase;
exports.validUsername = validUsername;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.to-string");
var _vue = _interopRequireDefault(require("vue"));
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}
function validMobile(mobile) {
  var reg = /^0?(13[0-9]|15[012356789]|17[013678]|18[0-9]|14[57])[0-9]{8}$/;
  return reg.test(mobile);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}
function isIntegerGreaterThanZero(arg) {
  return /(^[1-9]\d*$)/.test(arg);
}
function validMobileRule(rule, value, callback) {
  if (value == '') {
    callback('不能为空');
  } else if (value !== '' && !validMobile(value)) {
    callback('请输入合法的手机号');
  } else {
    callback();
  }
}
function validPositiveNumber(dotNum) {
  return function (rule, value, callback) {
    if (value) {
      if (!/^[0-9]+(.[0-9]{1,2})?\d*$/.test(value)) {
        callback('请输入正确的数字');
      } else if (dotNum && value.split('.')[1] && value.split('.')[1].length > dotNum) {
        callback('最多只能输入' + dotNum + '位小数');
      } else {
        callback();
      }
    } else {
      callback();
    }
  };
}