exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "rgba(255,255,255,0.7)",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#fff",
	"menuBg": "#272c33",
	"menuHover": "#rgba 0,0,0,0.1",
	"subMenuBg": "#272c33",
	"subMenuHover": "#rgba 0,0,0,0.1",
	"sideBarWidth": "165px",
	"menuTextHover": "#fff"
};