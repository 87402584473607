"use strict";

var _interopRequireDefault = require("D:/dy/outside/iot_console/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  computed: {
    language: function language() {
      return this.$store.state.setting.language;
    }
  },
  methods: {
    // handleSetLanguage(lang) {
    //   this.$i18n.locale = lang
    //   this.$store.commit('setting/setLanguage', lang)
    //   this.$message({
    //     message: this.$t('tips.switchLanguageSuccess'),
    //     type: 'success'
    //   })
    // }
    handleSetLanguage: function handleSetLanguage(lang) {
      this.language = lang;
      _localstorage.default.save("LANGUAGE", lang);
      this.$store.commit("setting/setLanguage", lang);
      this.deleteCache();
      this.$message({
        message: this.$t("tips.switchLanguageSuccess"),
        type: "success"
      });
    },
    deleteCache: function deleteCache() {
      _localstorage.default.remove("USER_ROUTER");
      _localstorage.default.remove("PERMISSIONS");
      location.reload();
      this.$i18n.locale = lang;
    }
  }
};