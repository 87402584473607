"use strict";

var _interopRequireDefault = require("D:/dy/outside/iot_console/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
var _toConsumableArray2 = _interopRequireDefault(require("D:/dy/outside/iot_console/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:/dy/outside/iot_console/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));
var _fuse = _interopRequireDefault(require("fuse.js"));
var _path = _interopRequireDefault(require("path"));
var _lang = _interopRequireDefault(require("@/lang"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// fuse is a lightweight fuzzy-search module
// make search results more in line with expectations
var _default = exports.default = {
  name: 'HeaderSearch',
  data: function data() {
    return {
      search: '',
      options: [],
      searchPool: [],
      show: false,
      fuse: undefined
    };
  },
  computed: {
    routes: function routes() {
      return this.$store.state.account.routes;
    },
    lang: function lang() {
      return this.$store.state.setting.language;
    }
  },
  watch: {
    lang: function lang() {
      this.searchPool = this.generateRoutes(this.routes);
    },
    routes: function routes() {
      this.searchPool = this.generateRoutes(this.routes);
    },
    searchPool: function searchPool(list) {
      this.initFuse(list);
    },
    show: function show(value) {
      if (value) {
        document.body.addEventListener('click', this.close);
      } else {
        document.body.removeEventListener('click', this.close);
      }
    }
  },
  mounted: function mounted() {
    this.searchPool = this.generateRoutes(this.routes);
  },
  methods: {
    click: function click() {
      this.show = !this.show;
      if (this.show) {
        this.$refs.headerSearchSelect && this.$refs.headerSearchSelect.focus();
      }
    },
    close: function close() {
      this.$refs.headerSearchSelect && this.$refs.headerSearchSelect.blur();
      this.options = [];
      this.show = false;
    },
    change: function change(val) {
      var _this = this;
      this.$router.push(val.path);
      this.search = '';
      this.options = [];
      this.$nextTick(function () {
        _this.show = false;
      });
    },
    initFuse: function initFuse(list) {
      this.fuse = new _fuse.default(list, {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [{
          name: 'title',
          weight: 0.7
        }, {
          name: 'path',
          weight: 0.3
        }]
      });
    },
    // Filter out the routes that can be displayed in the sidebar
    // And generate the internationalized title
    generateRoutes: function generateRoutes(routes) {
      var basePath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
      var prefixTitle = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var res = [];
      var _iterator = (0, _createForOfIteratorHelper2.default)(routes),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var router = _step.value;
          // skip hidden router
          if (router.hidden) {
            continue;
          }
          var data = {
            path: _path.default.resolve(basePath, router.path),
            title: (0, _toConsumableArray2.default)(prefixTitle)
          };
          if (router.meta && router.meta.title) {
            // generate internationalized title
            var i18ntitle = _lang.default.t("".concat(router.meta.title));
            data.title = [].concat((0, _toConsumableArray2.default)(data.title), [i18ntitle]);
            if (router.redirect !== 'noRedirect') {
              // only push the routes with title
              // special case: need to exclude parent router without redirect
              res.push(data);
            }
          }

          // recursive child routes
          if (router.children) {
            var tempRoutes = this.generateRoutes(router.children, data.path, data.title);
            if (tempRoutes.length >= 1) {
              res = [].concat((0, _toConsumableArray2.default)(res), (0, _toConsumableArray2.default)(tempRoutes));
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return res;
    },
    querySearch: function querySearch(query) {
      if (query !== '') {
        this.options = this.fuse.search(query);
      } else {
        this.options = [];
      }
    }
  }
};