var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _vm.loading
        ? _c("div", { staticClass: "loading-box" }, [
            _c("p", [_vm._v("加载中...")]),
          ])
        : _c("div", { staticClass: "loading-box" }, [
            _c("p", [_vm._v("暂无数据")]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }