var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "upload-box" },
      [
        _c(
          "el-upload",
          {
            ref: "upload",
            staticClass: "upload-demo",
            attrs: {
              action: _vm.uploadurl,
              "on-change": _vm.uploadChange,
              "show-file-list": false,
              "http-request": _vm.uploadFile,
              "before-upload": _vm.beforeUpload,
              accept: _vm.accept,
            },
          },
          [
            _c(
              "div",
              [
                _c("el-button", { attrs: { type: "primary" } }, [
                  _vm._v("选择文件"),
                ]),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "filelist-box" },
      [
        _vm._l(_vm.fileList, function (item) {
          return _c("div", { key: item.uid }, [
            item.status == "success"
              ? _c("div", { staticClass: "filelist" }, [
                  _c(
                    "div",
                    {
                      staticClass: "filelist-box-left pointer",
                      on: {
                        click: function ($event) {
                          return _vm.download(item.url)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-paperclip",
                        staticStyle: { "margin-right": "10px" },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(item.name))]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("i", {
                      staticClass: "el-icon-delete pointer",
                      on: {
                        click: function ($event) {
                          return _vm.delFile(item.uid)
                        },
                      },
                    }),
                  ]),
                ])
              : item.status == "fail"
              ? _c("div", { staticClass: "filelist" }, [
                  _c("div", { staticClass: "filelist-box-left" }, [
                    _c("i", {
                      staticClass: "el-icon-error",
                      staticStyle: { color: "#cb4538", "margin-right": "10px" },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(item.name))]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "filelist-error-right" }, [
                    _c("i", {
                      staticClass: "el-icon-refresh-right pointer",
                      staticStyle: { margin: "0 10px" },
                      on: {
                        click: function ($event) {
                          return _vm.uploadAgain(item)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "el-icon-delete pointer",
                      on: {
                        click: function ($event) {
                          return _vm.delFile(item.uid)
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e(),
          ])
        }),
        _vm._v(" "),
        _vm.progressData.progress
          ? _c(
              "div",
              { staticClass: "progress" },
              [
                _c("div", { staticClass: "progress-text" }, [
                  _vm._v("\n        " + _vm._s(_vm.progressData.name)),
                  _c("span", [_vm._v("(" + _vm._s(_vm.fileSize) + "M)")]),
                ]),
                _vm._v(" "),
                _c("el-progress", {
                  attrs: {
                    percentage: _vm.progressData.progress,
                    "show-text": false,
                  },
                }),
                _vm._v(" "),
                _c("i", {
                  staticClass: "el-icon-close pointer",
                  on: { click: _vm.toUploadAbort },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }