"use strict";

var _interopRequireDefault = require("D:/dy/outside/iot_console/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.myMixin = void 0;
var _index = require("@/utils/index");
var _numDefEmpty = _interopRequireDefault(require("@/utils/numDefEmpty"));
var myMixin = exports.myMixin = {
  computed: {
    getTimeZone: function getTimeZone() {
      return function (time, cFormat) {
        // "2022-07-08T10:19:35"
        if (time) {
          // var timezone = 16; //目标时区时间，东八区
          // var offset_GMT = new Date().getTimezoneOffset(); // 本地时间和格林威治的时间差，单位为分钟
          // var nowDate = new Date(time).getTime(); // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
          // var targetDate = new Date(nowDate + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000);
          // return parseTime(targetDate)
          if (typeof time == "number") {
            var _d = new Date(time);
            return (0, _index.parseTime)(_d, cFormat);
          } else {
            var d = new Date(time);
            var localTime = d.getTime();
            var localOffset = d.getTimezoneOffset() * 60000;
            var utc = localTime + localOffset;
            var offset = 16;
            var userTimezoneTime = utc + 3600000 * offset;
            var nd = new Date(userTimezoneTime);
            return (0, _index.parseTime)(nd, cFormat);
          }
        } else {
          return "-";
        }
      };
    }
  },
  methods: {
    getQueryTimeZone: function getQueryTimeZone(time) {
      if (time) {
        var d = new Date(time);
        var localTime = d.getTime();
        var localOffset = d.getTimezoneOffset() * 60000;
        var utc = localTime - localOffset;
        var offset = 16;
        var serverTimezoneTime = utc - 3600000 * offset;
        var nd = new Date(serverTimezoneTime);
        return (0, _index.parseTime)(nd);
      } else {
        return "";
      }
    },
    getTimeZone1: function getTimeZone1(time) {
      if (time) {
        var d = new Date(time);
        var localTime = d.getTime();
        var localOffset = d.getTimezoneOffset() * 60000;
        var utc = localTime + localOffset;
        var offset = 16;
        var userTimezoneTime = utc + 3600000 * offset;
        var nd = new Date(userTimezoneTime);
        return (0, _index.parseTime)(nd, "{y}-{m}-{d}");
      } else {
        return "";
      }
    },
    getTimezoneOffset: function getTimezoneOffset() {
      // let timezoneOffset = new Date().getTimezoneOffset();
      // if (timezoneOffset < 0) {
      //   timezoneOffset = 24 * 60 + timezoneOffset;
      // }
      // return timezoneOffset;
      var date = new Date();
      var timezoneOffset = 0;
      if (date.getTimezoneOffset() < 0) {
        timezoneOffset = (24 + date.getTimezoneOffset() / 60) * 60;
      } else {
        timezoneOffset = Math.abs(date.getTimezoneOffset());
      }
      return timezoneOffset;
    },
    numDefEmpty: _numDefEmpty.default
  }
};