var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _c(
        "el-scrollbar",
        {
          staticStyle: { "background-color": "#323840" },
          attrs: { "wrap-class": "scrollbar-wrapper" },
        },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "unique-opened": true,
                "active-text-color": _vm.variables.menuActiveText,
                "collapse-transition": true,
                mode: "vertical",
              },
            },
            _vm._l(_vm.userRoutes, function (route) {
              return _c("sidebar-item", {
                key: route.path + (route.meta ? route.meta.title : ""),
                attrs: { item: route, "base-path": route.path },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }