"use strict";

var _interopRequireDefault = require("D:/dy/outside/iot_console/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("D:/dy/outside/iot_console/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _localstorage = _interopRequireDefault(require("@/utils/localstorage"));
var _request = _interopRequireDefault(require("@/utils/request"));
var _index = _interopRequireDefault(require("@/store/index"));
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
_vue.default.use(_vueRouter.default);
var constRouter = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/redirect/index'], resolve);
    }
  }]
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/error-page/404'], resolve);
  },
  hidden: true
}, {
  path: '/login',
  name: '登录页',
  component: function component(resolve) {
    return require(['@/views/login/index'], resolve);
  }
}, {
  path: '/',
  component: _layout.default,
  redirect: '/cockpit/dataAnalysis',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/cockpit/dataAnalysis/Index'], resolve);
    },
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      affix: true
    }
  },
  // {
  //   path: 'member/familyDetail',
  //   component: (resolve) => require(['@/views/member/family/detail'], resolve),
  //   name: 'memberFamilyDetail',
  //   meta:{title: "家庭详情"},
  // },
  // {
  //   path: 'member/tenantDetail',
  //   component: (resolve) => require(['@/views/member/tenant/detail'], resolve),
  //   name: 'memberTenantDetail',
  //   meta:{title: "租户详情"},
  // },
  // {
  //   path: 'member/mechanismDetail',
  //   component: (resolve) => require(['@/views/member/tenant/mechanismDetail'], resolve),
  //   name: 'memberMechanismDetail',
  //   meta:{title: "机构详情"},
  // },
  // {
  //   path: 'member/family/detail',
  //   component: (resolve) => require(['@/views/member/family/familyMemberDetail/Index'], resolve),
  //   name: 'memberFamilyMemberDetail',
  //   meta:{title: "会员详情"},
  // },
  {
    path: 'memberList',
    component: function component(resolve) {
      return require(['@/views/tenantRole/memberList'], resolve);
    },
    name: 'memberList',
    meta: {
      title: "会员列表"
    },
    children: [{
      path: 'detail',
      component: function component(resolve) {
        return require(['@/views/cockpit/dataAnalysis/Index'], resolve);
      },
      name: 'detail',
      meta: {
        title: '会员详情',
        icon: 'dashboard',
        affix: true
      }
    }]
  }, {
    path: 'alarm',
    component: function component(resolve) {
      return require(['@/views/tenantRole/alarm'], resolve);
    },
    name: 'alarm',
    meta: {
      title: "告警工单"
    }
  }, {
    path: 'device',
    component: function component(resolve) {
      return require(['@/views/tenantRole/device'], resolve);
    },
    name: 'device',
    meta: {
      title: "设备管理"
    }
  }, {
    path: 'mapTrack',
    component: function component(resolve) {
      return require(['@/views/tenantRole/mapTrack'], resolve);
    },
    name: 'mapTrack',
    meta: {
      title: "轨迹追踪"
    }
  }, {
    path: 'mapPosition',
    component: function component(resolve) {
      return require(['@/views/tenantRole/mapPosition'], resolve);
    },
    name: 'mapPosition',
    meta: {
      title: "定位地图"
    }
  }, {
    path: 'role',
    component: function component(resolve) {
      return require(['@/views/tenantRole/role'], resolve);
    },
    name: 'role',
    meta: {
      title: "角色管理"
    }
  }, {
    path: 'user',
    component: function component(resolve) {
      return require(['@/views/tenantRole/user'], resolve);
    },
    name: 'user',
    meta: {
      title: "员工管理"
    }
  }, {
    path: 'institution',
    component: function component(resolve) {
      return require(['@/views/institution/detail'], resolve);
    },
    name: 'institution',
    meta: {
      title: "机构详情"
    }
  }, {
    path: 'cockpit',
    component: function component(resolve) {
      return require(['@/views/cockpit/dataAnalysis/Index'], resolve);
    },
    name: 'cockpit',
    meta: {
      title: "驾驶舱"
    }
  }, {
    path: 'relatives',
    component: function component(resolve) {
      return require(['@/views/tenantRole/relatives'], resolve);
    },
    name: 'relatives',
    meta: {
      title: "亲友圈"
    }
  }, {
    path: 'contacts',
    component: function component(resolve) {
      return require(['@/views/tenantRole/contacts'], resolve);
    },
    name: 'contacts',
    meta: {
      title: "紧急联系人"
    }
  }]
}, {
  path: '/profile',
  component: _layout.default,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component(resolve) {
      return require(['@/views/profile/index'], resolve);
    },
    name: 'Profile',
    meta: {
      title: 'profile',
      icon: 'user',
      noCache: true
    }
  }]
}, {
  path: '/error',
  component: _layout.default,
  redirect: 'noRedirect',
  name: 'ErrorPages',
  meta: {
    title: 'errorPages',
    icon: '404'
  },
  children: [{
    path: '404',
    component: function component(resolve) {
      return require(['@/views/error-page/404'], resolve);
    },
    name: 'Page404',
    meta: {
      title: 'page404',
      noCache: true
    }
  }]
}];
var router = new _vueRouter.default({
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constRouter
});
var whiteList = ['/login'];
var asyncRouter;

// 导航守卫，渲染动态路由
router.beforeEach(function (to, from, next) {
  _nprogress.default.start();
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else {
    var token = _localstorage.default.get('ACCESS_TOKEN');
    var user = _localstorage.default.get('USER');
    var userRouter = get('USER_ROUTER');
    if (token.length && user) {
      if (!asyncRouter) {
        if (!userRouter) {
          _request.default.get("aiot-accountcenter/menu/".concat(user.username)).then(function (res) {
            var permissions = res.data.data.permissions;
            _index.default.commit('account/setPermissions', permissions);
            // cz  增加三级页面，二级页面高亮效果
            var dealFun = function dealFun(children, url) {
              children.forEach(function (p) {
                var arr = p.path.split("/");
                if (arr.length > 3) {
                  p.meta.activeMenu = "/".concat(arr[1], "/").concat(arr[2]);
                }
                // if(p.children &&  p.children.length){
                //   dealFun(p.children,url)
                // }
              });
            };
            var routerList = res.data.data.routes;
            routerList.forEach(function (p1) {
              if (p1.children && p1.children.length) {
                // p1.children.forEach(p2=>{
                //   if(p2.children && p2.children.length){
                //     dealFun(p2.children,p2.path)
                //   }
                // })
                dealFun(p1.children, p1.path);
              }
            });
            asyncRouter = routerList;
            // asyncRouter = res.data.data.routes
            _index.default.commit('account/setRoutes', asyncRouter);
            save('USER_ROUTER', asyncRouter);
            go(to, next);
          });
        } else {
          asyncRouter = userRouter;
          go(to, next);
        }
      } else {
        next();
      }
    } else {
      if (to.path === '/login') {
        next();
      } else {
        next('/login');
        _nprogress.default.done();
      }
    }
  }
});
router.afterEach(function (to, from) {
  if (to.path === '/login') {
    asyncRouter = null;
  }
  _nprogress.default.done();
});
function go(to, next) {
  asyncRouter = filterAsyncRouter(asyncRouter);
  router.addRoutes(asyncRouter);
  next((0, _objectSpread2.default)((0, _objectSpread2.default)({}, to), {}, {
    replace: true
  }));
}
function save(name, data) {
  localStorage.setItem(name, JSON.stringify(data));
}
function get(name) {
  return JSON.parse(localStorage.getItem(name));
}
function filterAsyncRouter(routes) {
  // console.log(routes)
  return routes.filter(function (route) {
    var component = route.component;
    if (component) {
      if (route.component === 'Layout') {
        route.component = _layout.default;
      } else {
        route.component = view(component);
      }
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children);
      }
      return true;
    }
  });
}
function view(path) {
  return function (resolve) {
    return require(["@/views/".concat(path, ".vue")], resolve);
  };
}
var _default = exports.default = router;