var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        "modal-append-to-body": false,
        title: "系统布局配置",
        visible: _vm.flag,
        "close-on-press-escape": false,
        "wrapper-closable": true,
        size: 300,
        "before-close": _vm.handleClose,
        direction: "rtl",
      },
      on: {
        "update:visible": function ($event) {
          _vm.flag = $event
        },
      },
    },
    [
      _c("div", { staticClass: "drawer-container" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "drawer-item" },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("settings.theme")))]),
              _vm._v(" "),
              _c("theme-picker", {
                staticStyle: {
                  float: "right",
                  height: "26px",
                  margin: "-3px 8px 0 0",
                },
                on: { change: _vm.themeChange },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "drawer-item" },
            [
              _c("span", [_vm._v("侧栏风格")]),
              _vm._v(" "),
              _c("el-switch", {
                staticClass: "drawer-switch",
                attrs: {
                  "active-value": "dark",
                  "inactive-value": "white",
                  "active-text": "黑色",
                  "inactive-text": "白色",
                },
                model: {
                  value: _vm.sidebarColor,
                  callback: function ($$v) {
                    _vm.sidebarColor = $$v
                  },
                  expression: "sidebarColor",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "drawer-item" },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("settings.tagsView")))]),
              _vm._v(" "),
              _c("el-switch", {
                staticClass: "drawer-switch",
                attrs: { "active-text": "开启", "inactive-text": "关闭" },
                model: {
                  value: _vm.tagsView,
                  callback: function ($$v) {
                    _vm.tagsView = $$v
                  },
                  expression: "tagsView",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "drawer-item" },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("settings.fixedHeader")))]),
              _vm._v(" "),
              _c("el-switch", {
                staticClass: "drawer-switch",
                attrs: { "active-text": "固定", "inactive-text": "随动" },
                model: {
                  value: _vm.fixedHeader,
                  callback: function ($$v) {
                    _vm.fixedHeader = $$v
                  },
                  expression: "fixedHeader",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "drawer-item" },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("settings.sidebarLogo")))]),
              _vm._v(" "),
              _c("el-switch", {
                staticClass: "drawer-switch",
                attrs: { "active-text": "开启", "inactive-text": "关闭" },
                model: {
                  value: _vm.sidebarLogo,
                  callback: function ($$v) {
                    _vm.sidebarLogo = $$v
                  },
                  expression: "sidebarLogo",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }